'use strict';
//      

                
                  
                                
                       
   

const config         = {
    API_URL: 'https://api.mapbox.com',
    REQUIRE_ACCESS_TOKEN: true,
    ACCESS_TOKEN: null
};

module.exports = config;
